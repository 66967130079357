<template>
    <div class="history">
        <history-filter-block :is-load-xml="condition.idLoadXml"
                              @download-xls="downloadXls()"
                              :is-load-data="condition.isLoadTable"
                              :orders="orders"
                              @init-orders-list="initList(1)"
                              ref="filter"></history-filter-block>
        <orders-list ref="orderList"
                     @init-orders-list="initList()"
                     @filter-status-wrap="filterStatusWrap()"
                     :order-statuses="orderStatuses"
                     :count-prolongation="countProlongation"
                     :is-load-data="condition.isLoadTable"
                     :is-mobile="this.condition.isMobile"
                     :orders="orders"></orders-list>
        <div style="text-align: center">
            <pagination v-show="total > 0" :total="total"
                        :page.sync="listQuery.page"
                        :limit.sync="listQuery.limit" @pagination="initList()" />
        </div>
    </div>
</template>


<script>
    import Vue from "vue";
    import {orderMixin} from '@/mixin'
    import Pagination from '@/components/Pagination';
    import {list as listOrders, prolongation as listProlongation,  downloadExcel} from "@/api/order";
    import { default as ordersList } from "@/views/history/ordersList";
    import { default as HistoryFilterBlock } from "@/views/history/filterBlock";

    const FileDownload = require('js-file-download');

    export default {
        mixins: [orderMixin],
        components: {
            ordersList,
            Pagination,
            HistoryFilterBlock
        },
        data: function () {
            return {
                orders: [],
                total: 1,
                countProlongation: 0,
                mobileWith: 992,
                condition: {
                    isLoadTable: true,
                    isLoadXml: false,
                    isMobile: false,
                },
                listQuery: {
                    page: 1,
                    limit: 10,
                }
            }
        },
        created: function() {
            window.addEventListener("resize", this.checkWindowResize);
            this.checkIsMobile();
        },
        mounted: function() { // The hook. Here, Vue has already worked its magic.
            this.initList();
        },
        computed:{
          orderStatuses: function () {
            return this.$store.getters.ORDER_STATUSES;
          },
        },
        methods: {
            checkIsMobile: function() {
                return this.condition.isMobile = window.innerWidth < this.mobileWith;
            },
            checkWindowResize: function() {
                this.checkIsMobile()
            },
            filterStatusWrap: function() {
                this.$refs.filter.filterStatusWrap(true);
            },
            getQuery: function () {
                return {...this.$refs.filter.listQuery, ...this.$refs.orderList.listQuery, ...this.listQuery};
            },
            downloadXls : async function() {
                Vue.set(this.condition, 'idLoadXml', true);

                let response = await downloadExcel(this.getQuery());

                if (response?.data) {
                    FileDownload(response.data, 'report.xlsx');
                }

                Vue.set(this.condition, 'idLoadXml', false);
            },
            initList: async function (filterButtonEvent) {
                if (filterButtonEvent === 1) {
                    this.listQuery.page = 1;
                }
                let response;
                this.orders = [];
                this.condition.isLoadTable = true;

                if (this.$refs.orderList.listQuery.statusId === this.prolongationStatus.id) {
                    response = await listProlongation(this.getQuery());
                } else {
                    response = await listOrders(this.getQuery());
                }
                const { data } = response;

                this.countProlongation = data.data?.countProlongation ?? data.data?.total;
                this.orders = data.data.items;
                this.total = data.data?.total;
                this.condition.isLoadTable = false;

            },
        },
        destroyed() {
            window.removeEventListener("resize", this.checkWindowResize);
        },
    }
</script>
